import {
  Alert,
  Box,
  Button,
  FileUpload,
  Flashbar,
  FlashbarProps,
  FormField,
  Header,
  Modal,
  NonCancelableCustomEvent,
  SpaceBetween,
  SelectProps,
} from "@amzn/awsui-components-react";
import React from "react";
import { XTerm } from "xterm-for-react";
import { FitAddon } from "./customFit";
import { NumberSize, Resizable } from "re-resizable";
import Type = FlashbarProps.Type;
import { v4 } from "uuid";
import {
  WebSocketCommand,
  PTYSessionResize,
  S3Download,
  DreamscapeMessage
} from "@amzn/coral_com-amazonaws-console-dreamscape-model";
import { Direction } from "re-resizable/lib/resizer";
import {
  DefaultApi,
  StartSessionRequest,
  SessionState,
} from "../dreamscape-api/generated-src";
import DreamscapeApiFactory from "../dreamscape-api/DreamscapeApiFactory";
import { getAppSetting } from "../config/AppSettings";
import { getMidwayJwtToken } from "../auth/MidwayJwtToken";
import { FileUploadProps } from "@amzn/awsui-components-react/polaris/file-upload/interfaces";
import { hideLoading, showLoading } from "./Loading";

interface XtermProps {
  sessionId: string
  sessionName: string
  closeXterm: any
  xtermCols: number
  xtermRows: number
}

interface XtermState {
  sessionName: string
  flash: FlashbarProps.MessageDefinition[]
  showErrorModal: boolean
  fileUploadModal: boolean
  errorMessage: string
  errorMessages: string[] | undefined
  sessionState: string
  xtermCols: number
  xtermRows: number
  actualCellDimensions: [number, number] | undefined
  fileUploadList: File[]

  showPromoteModal: boolean
  scriptList: SelectProps.Option[]
  authGroups: SelectProps.Option[]
  selectedScript: string
  selectedAuthGroup: string
}

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "solid 2px lightgray"
};

export const BLANK_SESSION_ID = "00000000-0000-0000-0000-000000000000"
export const BLANK_HARDWARE_ID = "UNK.UNKNOWN"

const TERMINAL_PADDING = 3

export class Xterm extends React.Component<XtermProps, XtermState> {
  webSocketBaseUrl: string
  connectionFlashId: string | undefined
  webSocket: WebSocket | undefined
  sessionId: string
  sessionName: string
  resizableRef: React.RefObject<any>
  xtermRef: React.RefObject<any>
  xtermFitAddon: FitAddon
  nameRef: React.RefObject<any>
  dreamscapeApi: DefaultApi

  constructor(props: XtermProps) {
    super(props);
    this.webSocketBaseUrl = `wss://${getAppSetting('wsUrl')}`;
    this.connectionFlashId = undefined;
    this.sessionName = props.sessionName;
    this.sessionId = props.sessionId;
    this.webSocket = undefined;
    this.resizableRef = React.createRef();
    this.xtermRef = React.createRef();
    this.xtermFitAddon = new FitAddon();
    this.nameRef = React.createRef();
    this.state = {
      sessionName: props.sessionName,
      flash: [],
      showErrorModal: false,
      fileUploadModal: false,
      errorMessage: "",
      errorMessages: undefined,
      sessionState: SessionState.New,
      xtermCols: props.xtermCols,
      xtermRows: props.xtermRows,
      actualCellDimensions: undefined,
      fileUploadList: [],
      showPromoteModal: false,
      scriptList: [],
      authGroups: [],
      selectedScript: "",
      selectedAuthGroup: "",
    };
    this.dreamscapeApi = DreamscapeApiFactory();
  }

  componentDidMount = () => {
    this.resizeResizable();
    if (this.sessionId === BLANK_SESSION_ID) {
      return;
    }
    this.scheduleConnect();
    this.schedulePing();
  }

  componentWillUnmount = () => {
    if (this.sessionId === BLANK_SESSION_ID) {
      return;
    }
    this.disconnect();
  }

  scheduleConnect = () => {
    setTimeout(() => {
      this.connect();
    }, 1000);
  }

  schedulePing = () => {
    const intervalId = setInterval(() => {
      if (!this.sendPing()) {
        clearInterval(intervalId);
      }
    }, 10000);
  }

  sendPing = (): boolean => {
    // sending ping only if already connected
    if (!this.isOpen()) {
      return true;
    }
    const pingMessage = DreamscapeMessage.fromJson(
      {
        action: WebSocketCommand.PING,
        sessionId: this.sessionId,
        data: JSON.stringify({
          sessionId: this.sessionId
        })
      }
    )
    this.webSocket!.send(JSON.stringify(DreamscapeMessage.toJson(pingMessage)));
    return true;
  }

  sendPong = () => {
    if (!this.isOpen()) {
      return;
    }
    this.webSocket!.send(JSON.stringify({
      action: WebSocketCommand.PONG,
      sessionId: this.sessionId
    }));
  }

  sendResize = () => {
    if (!this.isOpen()) {
      return
    }
    const ptySessionResize = PTYSessionResize.clone({
      sessionId: this.sessionId,
      cols: this.state.xtermCols,
      rows: this.state.xtermRows
    })
    const resizeMessage = DreamscapeMessage.fromJson({
      action: WebSocketCommand.RESIZE,
      sessionId: this.sessionId,
      data: JSON.stringify(PTYSessionResize.toJson(ptySessionResize))
    });
    this.webSocket!.send(JSON.stringify(DreamscapeMessage.toJson(resizeMessage)));
  }

  sendNotify = (bucket: String, key: String): void => {
    if (!this.isOpen()) {
      return
    }

    const s3Download: S3Download = S3Download.clone({
      bucket: bucket.toString(),
      key: key.toString()
    })

    const notificationMessage = DreamscapeMessage.fromJson({
      action: WebSocketCommand.FILE_UPLOAD_NOTIFY,
      sessionId: this.sessionId,
      data: JSON.stringify(S3Download.toJson(s3Download))
    });

    this.webSocket!.send(JSON.stringify(DreamscapeMessage.toJson(notificationMessage)));
  }

  sendReplay = () => {
    if (!this.isOpen()) {
      return
    }
    const replayMessage = DreamscapeMessage.fromJson({
      action: WebSocketCommand.REPLAY,
      sessionId: this.sessionId
    });
    this.xtermRef.current.terminal.clear();
    this.webSocket!.send(JSON.stringify(DreamscapeMessage.toJson(replayMessage)));
  }

  showFlash = (type: Type, msg: string): string => {
    const flashId = v4();
    this.setState({
      flash: [...this.state.flash, { type: type, content: msg, dismissLabel: flashId }]
    });
    return flashId;
  }

  dismissFlash = (flashId: string | undefined) => {
    if (flashId === undefined) {
      return;
    }
    let flash = this.state.flash;
    flash = flash.filter((item) => item.dismissLabel !== flashId);
    this.setState({
      flash: flash
    })
  }

  showConnectionFlash = (type: Type, msg: string) => {
    this.dismissConnectionFlash();
    this.connectionFlashId = this.showFlash(type, msg);
  }

  dismissConnectionFlash = () => {
    if (this.connectionFlashId === undefined) {
      return;
    }
    this.dismissFlash(this.connectionFlashId);
    this.connectionFlashId = undefined;
  }

  clearFlash = () => {
    if (this.state.flash.length > 0) {
      this.setState({ flash: [] });
    }
  }

  connect = () => {
    const me = this;
    this.showConnectionFlash("info", "Establishing connection...");
    this.dreamscapeApi.getSession({ sessionId: this.sessionId })
    .then(resp => {
      if (resp.data.error) {
        this.showError(resp.data.error, resp.data.errors);
        return;
      }
      const session = resp.data.session!;
      // connecting only if session is not closed
      if (session.sessionState === SessionState.Closed) {
        this.dismissConnectionFlash();
        return;
      }
      const webSocketSetup = (federationData: string | undefined = undefined) => {
        getMidwayJwtToken().then(jwtToken => {
          this.webSocket = new WebSocket(`${this.webSocketBaseUrl}?sessionId=${this.sessionId}&authorizer=${jwtToken}`);
          this.webSocket.addEventListener("open", function (e: Event) {
            const startSessionRequest: StartSessionRequest = {
              sessionId: me.sessionId
            };
            if (federationData !== undefined) {
              startSessionRequest.federationData = federationData;
            }
            me.dreamscapeApi.startSession(startSessionRequest)
            .then(resp => {
              if (resp.data.error) {
                me.showError(resp.data.error, resp.data.errors);
                return;
              }
              // immediately send ping once websocket connected, this takes session to grace mode
              me.sendPing();
              me.xtermRef.current.terminal.clear();
            });
          });
          this.webSocket.addEventListener("message", (e: MessageEvent) => {
            const payload = DreamscapeMessage.fromJson(JSON.parse(e.data));
            const action = payload.action;
            const data = payload.data;
            if (payload.sessionId != this.sessionId) return;
            switch (action) {
              case WebSocketCommand.PING:
                this.sendPong();
                break;
              case WebSocketCommand.PONG:
                if (data) {
                  const sessionPong = JSON.parse(data);
                  if (sessionPong.statusCode != 200) {
                    break;
                  }
                  const prevSessionState = this.state.sessionState;
                  const sessionState = sessionPong.sessionState;
                  this.setState({ sessionState: sessionState });
                  switch (sessionState) {
                    case SessionState.Active:
                      // clearing flash
                      this.clearFlash();
                      if (prevSessionState !== sessionState) {
                        this.sendReplay();
                      }
                      break;
                    case SessionState.Closed:
                      this.disconnect();
                      this.props.closeXterm(this.sessionId);
                      break;
                    case SessionState.Failed:
                      this.disconnect();
                      let errorMessage = "Session has failed";
                      if (sessionPong.sessionStateReason !== null && sessionPong.sessionStateReason !== "") {
                        errorMessage = sessionPong.sessionStateReason;
                      }
                      this.showError(errorMessage);
                      break;
                  }
                }
                break;
              case WebSocketCommand.DATA:
                if (this.state.flash.length > 0) {
                  this.dismissConnectionFlash();
                }
                if (data) {
                  this.xtermRef.current.terminal.write(atob(data));
                }
                break;
              case WebSocketCommand.REPLAY:
                this.xtermRef.current.terminal.clear();
                if (data) {
                  this.xtermRef.current.terminal.write(atob(data));
                }
            }
          });
          this.webSocket.addEventListener("close", (e: Event) => {
            this.showConnectionFlash("warning", `WebSocket connection has been closed, reconnecting...`);
            this.scheduleConnect();
          });

          this.webSocket.addEventListener("error", (e: Event) => {
            this.showConnectionFlash("error", "WebSocket connection has failed, NOT scheduling connect");
          });
        });
      }
      if (session.federationAccountEmail && session.federationRoleName) {
        // @ts-ignore
        if (typeof document.getFederationAccountId !== "undefined") {
          // @ts-ignore
          document.getFederationAccountId(
            session.fabric,
            session.availabilityZone,
            session.federationAccountEmail)
          .then(accountId => {
            // @ts-ignore
            if (typeof document.getAssumeRoleCredentials !== "undefined") {
              // @ts-ignore
              document.getAssumeRoleCredentials(
                session.fabric,
                session.availabilityZone,
                accountId,
                session.federationRoleName)
              .then(assumeRoleResult => {
                webSocketSetup(assumeRoleResult);
              })
              .catch(() => {
                this.showError(`Dreamscape/Isengard federation has failed`);
              });
            } else {
              this.showError(`Dreamscape/Isengard federation requires user script installed`);
            }
          })
          .catch(() => {
            this.showError(`Unable to find AWS account ID for ${session.federationAccountEmail}`);
          })
        } else {
          this.showError(`Dreamscape/Isengard federation requires user script installed`);
        }
      } else {
        webSocketSetup();
      }
    })
    .catch(() => {
      this.showConnectionFlash("warning", "Failed to retrieve session information, retrying....");
      this.scheduleConnect();
      //UNCOMMENT
    });
  }

  showError = (message: string, messages: string[] | undefined = undefined) => {
    this.setState({
      showErrorModal: true,
      errorMessage: message,
      errorMessages: messages
    })
  }

  disconnect = () => {
    if (this.webSocket !== undefined) {
      this.webSocket.close(1000, "exit");
      this.webSocket = undefined;
    }
  }

  getInfoButtonText = () => {
    if (this.sessionId === BLANK_SESSION_ID) {
      return "New";
    }
    switch (this.state.sessionState) {
      case SessionState.New:
        return "Initializing";
      case SessionState.Active:
        return "Active";
      case SessionState.Grace:
        return "Pending";
      case SessionState.Closed:
        return "Closed";
      default:
        return "Disconnected";
    }
  }

  isOpen = () => {
    return this.webSocket !== undefined && this.webSocket.readyState === WebSocket.OPEN;
  }

  isSessionActive = () => {
    return this.state.sessionState === SessionState.Active;
  }

  resizeResizable = () => {
    this.setState({
      actualCellDimensions: this.xtermFitAddon.actualCellDimensions(),
      xtermCols: this.xtermRef.current.terminal.cols,
      xtermRows: this.xtermRef.current.terminal.rows
    });
    const actualTerminalSize = this.xtermFitAddon.actualTerminalSize(TERMINAL_PADDING);
    if (actualTerminalSize !== undefined) {
      this.resizableRef.current.updateSize(actualTerminalSize);
    }
  }

  xtermOnData = (data: string) => {
    if (this.isOpen() && this.isSessionActive()) {
      const dataMessage = DreamscapeMessage.fromJson({
        action: WebSocketCommand.DATA,
        sessionId: this.sessionId,
        data: btoa(data)
      })
      this.webSocket!.send(JSON.stringify(DreamscapeMessage.toJson(dataMessage)));
    }
  }

  errorMessageList = (errorMessages: string[] | undefined) => {
    if (errorMessages === undefined) {
      return <></>;
    }
    const errorMessageListItems = errorMessages.map((errorMessage) => {
      return <li>{errorMessage}</li>;
    });
    return <ul>{errorMessageListItems}</ul>
  }

  uploadFiles = async (files: File[]): Promise<string[]> => {
    let errors: string[] = [];
    for (const file of files) {
      // const contentType = file.type || 'application/octet-stream';
      const contentType = file.type || 'text/plain';
      let signedUrl: string | undefined = undefined;
      let bucket: string | undefined = undefined;
      let key: string | undefined = undefined;
      try {
        const resp = await this.dreamscapeApi.uploadFile({
          sessionId: this.sessionId,
          fileName: file.name,
          contentType: contentType,
        });
        if (resp.data.error) {
          this.showError(resp.data.error, resp.data.errors);
          continue;
        }
        signedUrl = resp.data.signedUrl;
        bucket = resp.data.bucket;
        key = resp.data.key;
      } catch (e) {
        errors.push(`${e}`);
        continue;
      }
      if (signedUrl === undefined || signedUrl === "") {
        continue;
      }
      try {
        const uploadResp = await fetch(signedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": contentType,
          }
        });
        if (!uploadResp.ok) {
          errors.push(uploadResp.statusText);
        } else {
          if (bucket === undefined || bucket === "") {
            continue;
          }
          if (key === undefined || key === "") {
            continue
          }
          this.sendNotify(bucket, key)
        }
      } catch (e) {
        errors.push(`${e}`)
      }
    }
    return errors;
  }

  render = () => {
    return (
      <div id={this.sessionId}>
        <Flashbar items={this.state.flash}/>
        <Modal
          visible={this.state.showErrorModal}
          header={"Error"}
          size={"medium"}
          onDismiss={() => {
            this.setState({ showErrorModal: false, errorMessage: "" })
          }}
          footer={""}
        >
          <Alert
            visible={true}
            type={"error"}
            header={"Error"}
          >
            {this.state.errorMessage}
            {this.errorMessageList(this.state.errorMessages)}
          </Alert>
        </Modal>
        <Modal
          visible={this.state.fileUploadModal}
          onDismiss={() => {
            this.setState({ fileUploadModal: false })
          }}
          closeAriaLabel={"Close modal"}
          size={"medium"}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="s">
                <Button variant="link" onClick={() => {
                  this.setState({ fileUploadModal: false });
                }}>
                  Cancel
                </Button>
                <Button variant={"primary"} onClick={() => {
                  showLoading();
                  this.uploadFiles(this.state.fileUploadList)
                  .then(errors => {
                    this.setState({ fileUploadModal: false });
                    hideLoading();
                    if (errors.length > 0) {
                      this.showError("Error occurred while uploading file(s)", errors);
                    }
                  }).catch(e => {
                    this.setState({ fileUploadModal: false });
                    hideLoading();
                    this.showError("Error occurred while uploading file(s)", [`${e}`]);
                  });
                }}>
                  Upload
                </Button>
              </SpaceBetween>
            </Box>
          }
          header={"File Upload"}
        >
          <FormField description="Files cannot be larger than 160GB.">
            <FileUpload
              onChange={
                (event: NonCancelableCustomEvent<FileUploadProps.ChangeDetail>) => {
                  this.setState({ fileUploadList: event.detail.value });
                }
              }
              value={this.state.fileUploadList}
              i18nStrings={{
                uploadButtonText: e => e ? "Choose files" : "Choose file",
                dropzoneText: e => e ? "Drop files to upload" : "Drop file to upload",
                removeFileAriaLabel: e => `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error"
              }}
              showFileLastModified
              showFileSize={true}
              showFileThumbnail
              tokenLimit={3}
              multiple={true}
              constraintText=""/>
          </FormField>
        </Modal>

        <Header variant={"h3"}
                actions={
                  <SpaceBetween direction="horizontal" size="m">
                    <Button
                      disabled={!this.isSessionActive()}
                      variant={"normal"}
                      iconName={"upload"}
                      onClick={() => {
                        this.setState({ fileUploadModal: true, fileUploadList: [] })
                      }}
                    >Upload</Button>
                    <Button
                      variant={"normal"}
                      iconName={"expand"}
                      onClick={() => {
                        this.xtermRef.current.terminal.resize(this.props.xtermCols, this.props.xtermRows);
                        this.resizeResizable();
                        this.sendResize();
                      }}
                    >
                      [{this.state.xtermCols}x{this.state.xtermRows}]
                    </Button>
                  </SpaceBetween>
                }>
          <Button
            variant={"normal"}
            iconName={"status-info"}
          >
            {this.getInfoButtonText()}
          </Button>
        </Header>
        <Resizable
          ref={this.resizableRef}
          style={style}
          onResize={(event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement, delta: NumberSize) => {
            this.xtermFitAddon.fit(elementRef, TERMINAL_PADDING);
            this.setState({
              xtermCols: this.xtermRef.current.terminal.cols,
              xtermRows: this.xtermRef.current.terminal.rows,
            })
            const actualTerminalSize = this.xtermFitAddon.actualTerminalSize(TERMINAL_PADDING);
            if (actualTerminalSize !== undefined) {
              this.resizableRef.current.updateSize(actualTerminalSize);
            }
          }}
          onResizeStop={(event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement, delta: NumberSize) => {
            this.sendResize();
          }}
          grid={this.state.actualCellDimensions}
        >
          <XTerm
            addons={[this.xtermFitAddon]}
            ref={this.xtermRef}
            options={{
              fontSize: 13,
              cols: this.state.xtermCols,
              rows: this.state.xtermRows
            }}
            onData={this.xtermOnData}
            onBinary={this.xtermOnData}
          />
        </Resizable>
      </div>
    );
  }
}